import React from 'react';

const BraintrustBanner = () => (
    <div className="bg-braintrust-yellow text-braintrust-black font-sans">
        <div className="py-2 px-8 md:flex items-center max-w-screen-md mx-auto">
            <div className="md:flex-1 text-center md:text-left">
                <p className="text-l md:text-xl font-semibold">
                    Access the world’s best freelance jobs.
                </p>
                <p className="md:text-l">
                    Your work. Your network. Your future.
                </p>
                <p className="text-[8px] opacity-70 mt-1 hidden md:block">
                    * Disclosure: At no additional cost to you, a commission
                    might be paid if you get accepted.
                </p>
            </div>
            <div className="text-center mt-4 md:text-right md:mt-0">
                <a
                    href="https://app.usebraintrust.com/r/michael224/"
                    target="_blank"
                    className="inline-block py-[8px] px-[30px] text-lg font-semibold whitespace-nowrap border-2 box-content rounded-full border-braintrust-black hover:bg-braintrust-black hover:text-white fix-bt-link"
                >
                    Apply to Braintrust
                </a>
                <p className="text-[8px] opacity-70 max-w-[240px] mx-auto md:hidden">
                    * Disclosure: This is an affiliate link.
                </p>
            </div>
        </div>
    </div>
);

export default BraintrustBanner;
