/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { CAPTURES } from '../utils/captures';
import { fileNameGenerator, imageSizeGenerator } from '../utils/helpers.js';

function SEO({ description, lang, title, slug }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;

    const meta = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary_large_image`,
        },
        {
            name: `twitter:creator`,
            content: `@michaelfasani`,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
    ];

    if (slug) {
        // Post Template passes a slug which means we should generate and image
        // OG and Twitter Image path
        const imageSize = imageSizeGenerator(CAPTURES, 'og');
        const fileName = fileNameGenerator(slug, 'og', imageSize);

        const imageOgPath =
            site.siteMetadata.siteUrl + `/social-images/${fileName}.png`;

        meta.push({
            name: `og:image`,
            content: imageOgPath,
        });
        meta.push({
            name: `twitter:image`,
            content: imageOgPath,
        });
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={meta}
        />
    );
}

SEO.defaultProps = {
    lang: `en`,
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default SEO;
