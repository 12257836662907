module.exports = {
    fileNameGenerator: function (slug, captureVariant, imageSize) {
        let slugAsName = slug;
        slugAsName = slugAsName.replace(/\//g, '-'); // Swap slash for dash
        slugAsName = slugAsName.substring(1); // Remove first dash

        const fileName = `${slugAsName}${imageSize}-${captureVariant}`;
        return fileName;
    },
    imageSizeGenerator: function (captures, captureVariant) {
        const width = captures[captureVariant].size[0];
        const height = captures[captureVariant].size[1];
        const imageSize = [`${width}x${height}`];
        return imageSize;
    },
};
