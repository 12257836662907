import React from 'react';
import LogoGitHub from './icons/github';
import LogoDev from './icons/dev';
import LogoMedium from './icons/medium';
import LogoHashnode from './icons/hashnode';
import LogoRss from './icons/rss';

const Footer = () => (
    <footer className="p-8 text-xs text-gray-400">
        <nav>
            <ul className="overflow-hidden mb-2">
                <li className="float-left mr-4 footer-svg">
                    <a
                        href="https://github.com/Fasani"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LogoGitHub />
                    </a>
                </li>
                <li className="float-left mr-4 footer-svg">
                    <a
                        href="https://dev.to/fasani"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LogoDev />
                    </a>
                </li>
                <li className="float-left mr-4 footer-svg">
                    <a
                        href="https://hashnode.com/@Fasani"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LogoHashnode />
                    </a>
                </li>
                <li className="float-left mr-4 footer-svg">
                    <a
                        href="https://medium.com/@michaelfasani"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LogoMedium />
                    </a>
                </li>
                <li className="float-left mr-4 footer-svg">
                    <a href="/rss.xml" target="_blank" rel="noreferrer">
                        <LogoRss />
                    </a>
                </li>
            </ul>
        </nav>
        <p>
            &copy; {new Date().getFullYear()} Michael Fasani, All rights
            reserved.
        </p>
    </footer>
);

export default Footer;
