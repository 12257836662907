module.exports = {
    CAPTURES: {
        hashnode: {
            size: [800, 420],
            template:
                './src/components/page-templates/social-images/generic.js',
        },
        dev: {
            size: [1000, 420],
            template:
                './src/components/page-templates/social-images/generic.js',
        },
        og: {
            size: [1200, 630],
            template:
                './src/components/page-templates/social-images/generic.js',
        },
        medium: {
            size: [1500, 750],
            template:
                './src/components/page-templates/social-images/generic.js',
        },
    },
};
