import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';

// Components
import Layout from '../components/layout';
import SEO from '../components/seo';

export default function TagsPage({ data }) {
    const group = data.allMarkdownRemark.group;

    return (
        <Layout>
            <SEO title="All Tags" />
            <div>
                <h1 className="header text-4xl">All Tags</h1>
                <ul>
                    {group.map((tag) => (
                        <li key={tag.fieldValue}>
                            <Link
                                to={`/tags/${tag.fieldValue}/`}
                                className={`tag tag--large tag--${tag.fieldValue}`}
                            >
                                #{tag.fieldValue}
                            </Link>
                            &nbsp;[{tag.totalCount}]
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    );
}

TagsPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            group: PropTypes.arrayOf(
                PropTypes.shape({
                    fieldValue: PropTypes.string.isRequired,
                    totalCount: PropTypes.number.isRequired,
                }).isRequired
            ),
        }),
    }),
};

export const query = graphql`
    query {
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`;
