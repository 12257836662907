import React from 'react';

const LogoRss = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 331.947 331.947"
        width="20"
        height="20"
        style={{ paddingTop: '4px' }}
    >
        <title>RSS</title>
        <circle cx="46.507" cy="285.333" r="46.507" />
        <path d="M0,120.64v60.373c83.307,0,150.827,67.627,150.827,150.827H211.2C211.2,215.253,116.587,120.64,0,120.64z" />
        <path d="M0,0v60.373c149.973,0,271.573,121.6,271.573,271.573h60.373C331.84,148.587,183.253,0,0,0z" />
    </svg>
);

export default LogoRss;
